import { NextSeo } from "next-seo";
import Custom404 from "@/components/404/Custom404";
// import { useSelector } from "react-redux";
import { getPortalSettings } from "@/store/actions";
import { wrapper } from "@/store/index";
import { END } from "redux-saga";

const NotFound = () => {
  // const { portalSettings } = useSelector((state) => state.portalSettings);

  return (
    <>
      <NextSeo
        title="الصفحة المطلوبة غير موجودة"
        additionalMetaTags={[
          {
            name: "description",
            content: "",
          },
          {
            name: "keywords",
            content: "",
          },
        ]}
      />
      <Custom404 />
    </>
  );
};

export const getStaticProps = wrapper.getStaticProps((store) => {
  return async () => {
    store.dispatch(getPortalSettings({ cookies: {} }));

    store.dispatch(END);
    await store.sagaTask.toPromise();

    return { props: {}, revalidate: 1 };
  };
});

export default NotFound;
