import Image from "next/future/image";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import { Container } from "react-bootstrap";
import styles from "./styles/style.module.scss";
import Icon404Light from "@/assets/svgs/404Light.svg";
import Icon404Dark from "@/assets/svgs/404.Dark.svg";
import vImage1 from "@/assets/images/v1.png";
import vImage2 from "@/assets/images/v2.png";
import vImage3 from "@/assets/images/v3.png";
import vImage4 from "@/assets/images/v4.png";
import vImage5 from "@/assets/images/v5.png";

const vImages = [vImage1, vImage2, vImage3, vImage4, vImage5];
const Custom404 = () => {
  // const router = useRouter();
  // const handleBack = () => {
  //   router.back();
  // };
  return (
    <div className={`${styles["custom404"]} custom404`}>
      <Container>
        <div className="inner flex-center">
          <div className="content flex-center">
            <div className="image-div flex-center light">
              <Icon404Light />
            </div>
            <div className="image-div flex-center dark">
              <Icon404Dark />
            </div>

            <h2>
              <FormattedMessage id="SorryAnErrorOccurred" />
            </h2>

            <Link href="/">
              <a className="btn-md">
                <FormattedMessage id="back" />
              </a>
            </Link>
          </div>
          {vImages.map((item, index) => (
            <div className={`iconBottom v${index + 1}`} key={index}>
              <Image src={item} alt="Banner" width={400} height={400} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Custom404;
